<template>
  <div>
    <div class="content">
      <div class="contentTop">
        <div class="contentTopTitle">
          <div class="title">计划分析</div>
          <div class="type">
            <div class="item">
              <div class="itemTitle">分析类型</div>
              <el-select v-premiSub="'计划分析_编辑'" v-model="typeValue" placeholder="" filterable>
                <el-option v-for="item in type" :key="item.id" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="item">
              <div class="itemTitle">排程A</div>
              <el-select v-premiSub="'计划分析_编辑'" v-model="benchmarkValue" placeholder="" filterable>
                <el-option v-for="item in benchmark" :key="item.id" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="item">
              <div class="itemTitle">排程B</div>
              <el-select v-premiSub="'计划分析_编辑'" v-model="contrastValue" placeholder="" filterable>
                <el-option v-for="item in contrast" :key="item.id" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="item">
              <div class="itemTitle">对比条件</div>
              <el-select v-premiSub="'计划分析_编辑'" v-model="conditionValue" placeholder="" filterable>
                <el-option v-for="item in condition" :key="item.id" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="contentTopRight">
          <el-tooltip v-premiSub="'计划分析_导出'" class="item" effect="dark" content="导出" placement="top">
            <img src="@/assets/images/plan/analysis/derive.png" alt="" @click="derive">
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="刷新" placement="top">
            <img src="@/assets/images/production/renovate.png" alt="" @click="renovate">
          </el-tooltip>
          <el-button v-premiSub="'查看报告'" @click="checkReport">查看报告</el-button>
        </div>
      </div>
      <el-table ref="multipleTable" :data="tempData" tooltip-effect="dark" style="width: 100%"
        :default-sort="{ order: 'descending' }">
        <el-table-column prop="showId" label="序号" fixed="left">
        </el-table-column>
        <el-table-column prop="productionNo" label="投产号">
        </el-table-column>
        <el-table-column prop="partCode" label="图号">
        </el-table-column>
        <el-table-column prop="plannedQuantity" label="数量">
        </el-table-column>
        <el-table-column prop="baseProductionCycle" label="排程A" sortable>
          <template slot-scope="scope">
            <div class="imgFlex">
              <div
                :class="{ bgColorGreen: (scope.row.baseItemStatus === 1 || scope.row.baseItemStatus === 2), bgColorRed: (scope.row.baseItemStatus === 3) }">
                {{
                  scope.row.baseProductionCycle
                }}</div>
              <img src="@/assets/images/plan/analysis/good.png" alt="" v-if="scope.row.recommendedItem === 'A'">
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="comparedProductionCycle" label="排程B" sortable>
          <template slot-scope="scope">
            <div class="imgFlex">
              <div
                :class="{ bgColorGreen: (scope.row.comparedItemStatus === 1 || scope.row.comparedItemStatus === 2), bgColorRed: (scope.row.comparedItemStatus === 3) }">
                {{
                  scope.row.comparedProductionCycle
                }}</div>
              <img src="@/assets/images/plan/analysis/good.png" alt="" v-if="scope.row.recommendedItem === 'B'">
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="differenceTime" v-if="(conditionValue === '生产周期')" label="差异周期">
        </el-table-column>
        <el-table-column prop="differenceTime" v-else-if="(conditionValue === '设备利用率')" label="设备利用率">
        </el-table-column>
        <el-table-column prop="differenceTime" v-else label="差异">
        </el-table-column>
      </el-table>
      <div class="totalFlex">
        <span>共{{ total }}条</span>
        <span>
          <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
            :current-page="currentPage1" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size=pageChange
            layout="prev, pager, next,sizes, jumper" :total=total>
          </el-pagination>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getAnalysls, deriveData, getSchedulingData } from '@/api/plan.js'
export default {
  name: "analysis",
  data() {
    return {
      tableData: [],
      tableData2: [],
      multipleSelection: [],
      currentPage1: 1,
      total: 0,
      tempData: [],
      section: [
        '已确认',
        '未确认'
      ],
      typeValue: '',     //分析类型-值
      benchmarkValue: '',   //基准排程-值
      contrastValue: '',    //对比排程-值
      conditionValue: '',    //对比条件-值
      type: [{
        value: '订单对比'
      },
        //  {
        //   value: '设备利用率对比'
        // }
      ],  //分析类型
      benchmark: [], //基准排程
      contrast: [],  //对比排程
      condition: [],  //对比条件
      value: '',
      pageChange: 10,   //当前页面多少条订单
      conditionTemp: '',
      typeTemp: '',
    }
  },
  watch: {
    typeValue: {
      handler(newVal) {
        if (newVal === '设备利用率对比') {
          this.conditionValue = ''
          this.condition = [{ value: '设备利用率' }]
        } else if (newVal === '订单对比') {
          this.conditionValue = ''
          this.condition = [{
            value: '交货期'
          }, {
            value: '生产周期'
          }]
        }
      }
    }
  },
  methods: {
    // 查看报告
    async checkReport() {
      if (this.typeValue && this.conditionValue && this.benchmarkValue) {
        if (this.conditionValue === '交货期') {
          this.conditionTemp = 1
        } else if (this.conditionValue === '生产周期') {
          this.conditionTemp = 2
        } else if (this.conditionValue === '设备利用率') {
          this.conditionTemp = 3
        }
        if (this.typeValue === '订单对比') {
          this.typeTemp = 1
        } else if (this.typeValue === '设备利用率对比') {
          this.typeTemp = 2
        }
        const res = await getAnalysls(1, 10, this.benchmarkValue, this.contrastValue, this.typeTemp, this.conditionTemp)
        if (res.code == 0) {
          this.tableData = res.data.records
          this.$nextTick(async () => {
            this.total = res.data.total
            const result = await getAnalysls(1, this.total, this.benchmarkValue, this.contrastValue, this.typeTemp, this.conditionTemp)
            this.tableData2 = result.data.records
          })
        } else {
          this.$message.error({
            showClose: true,
            message:res.msg,
            duration: 0
          })
          this.tableData = []
        }
      } else {
        this.$message.warning({
          showClose: true,
          message:'请选择分析条件！',
          duration: 0
        })
        return
      }
      this.currentPage1 = 1
      this.tempData = this.tableData
    },
    // 导出
    derive() {
      if (this.typeValue && this.conditionValue && this.benchmarkValue && this.contrastValue) {
        deriveData(1, this.total, this.benchmarkValue, this.contrastValue, this.typeTemp, this.conditionTemp).then(res => {
          this.filename = `计划分析${this.getDay()}.xlsx`
          this.url = window.URL.createObjectURL(res)
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = this.url
          link.setAttribute('download', this.filename)
          document.documentElement.appendChild(link)
          link.click()
          document.documentElement.removeChild(link)
        })
      } else {
        this.$message.warning({
          showClose: true,
          message:'请先选择分析条件！',
          duration: 0
        })
      }
    },
    getDay() {
      let time = new Date()
      let year = time.getFullYear()
      let month = time.getMonth() + 1
      let day = time.getDate()
      let hour = time.getHours()
      let minute = time.getMinutes()
      let second = time.getSeconds()
      if (String(minute).length == 1) {
        minute = '0' + minute
      }
      if (String(second).length == 1) {
        second = '0' + second
      }
      return `${year}-${month}-${day} ${hour}_${minute}_${second}`
    },
    // 分页组件--一页显示多少条数据
    handleSizeChange(val) {
      this.pageChange = val
      this.tempData = this.tableData2.filter((v, i) => i < val)
      this.currentPage1 = 1
    },
    // 分页组件--选择页数
    handleCurrentChange(val) {
      this.tempData = this.tableData2.slice(val * this.pageChange - this.pageChange, val * this.pageChange)
      this.currentPage1 = val
    },
    renovate() {
      // 清空所有选项
      this.typeValue = ''
      this.benchmarkValue = ''
      this.conditionValue = ''
      this.contrastValue = ''
      this.tempData = []
      this.total = 0
    }
  },
  async created() {
    if (sessionStorage.getItem('mark')) {
      const res1 = await getSchedulingData(1, sessionStorage.getItem('total_1'), 1)
      const res2 = await getSchedulingData(1, sessionStorage.getItem('total_2'), 3)
      if (res1.code == 0 && res2.code == 0) {
        let temp1 = res1.data.records.filter(v => v.planAnalysisState == 1)
        let temp2 = res2.data.records.filter(v => v.planAnalysisState == 1)
        let tempAll = [...temp1, ...temp2]
        tempAll.forEach((v, i) => {
          this.benchmark.push({ value: v.serialNumber, id: i })
          this.contrast.push({ value: v.serialNumber, id: i })
        })
      } else {
        this.$message.error({
          showClose: true,
          message:'获取排程数据失败！',
          duration: 0
        })
      }
    }
  },
  activated() {
    this.pageChange = 10
  }
}
</script>

<style lang="scss" scoped>
@import '../scheduling/style/style.scss'
</style>
